import React from 'react'
import "./loader.css";

export default function AdminLoader() {
    return (
        <div className='loader-main '>
            {/* <div className="loader">
                <div className="bar"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
                <div className="bar4"></div>
            </div> */}
            <div class="box ">
                <div class="loader-12"></div>
            </div>
        </div>
    )
}
