import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminLoader from "../componants/Loader";

const LazyHome = lazy(() => import('../pages/Home'));
// Replace this with a real delay or remove it in production
// const LazyHome = lazy(() => new Promise(resolve => setTimeout(() => resolve(import('../pages/Home')), 30000)));


export default function AllRoutes() {
  return (
    <Router>
      <Suspense fallback={<AdminLoader />}>
        
        <Routes>
          <Route path="/" element={<LazyHome />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
